<template>
  <div class="bannerUploader">
    <cropper
      :width="cropperWidth"
      :height="cropperHeight"
      :show="cropperShow"
      v-if="cropperShow"
      :img="cropperImg"
      @close="cropperShow = false"
      @submit="cropperSubmit"
    ></cropper>

    <div
      v-for="(item, index) in bannerList"
      :key="index"
      class="upload-container"
    >
      <div
        class="item-cover cursor-pointer"
        :class="{ active: selectedBanner.includes(item?.img) }"
        @click="handleSelectedImage(item)"
      >
        <img :src="item.img" style="object-fit: contain" />
      </div>
      <van-field
        v-model="item.link"
        placeholder="链接地址"
        rows="1"
        autosize
        type="textarea"
      />
    </div>

    <van-uploader :after-read="afterReadImage" :preview-image="false">
      <div class="item-upload cursor-pointer">
        <div class="icon"></div>
        <span class="upload-tips">点击上传</span>
      </div>
    </van-uploader>
  </div>
</template>

<script>
import cropper from "@/components/cropper.vue";
export default {
  components: { cropper },
  data() {
    return {
      bannerList: [],
      cropperShow: false,
      selectedBanner: [],
      cropperImg: null,
      cropperWidth: 672,
      cropperHeight: 187,
    };
  },
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  created() {},
  methods: {
    handleSelectedImage({ img }) {
      if (this.selectedBanner.includes(img)) {
        this.selectedBanner = this.selectedBanner.filter((i) => i !== img);
      } else {
        this.selectedBanner.push(img);
      }
      this.$emit(
        "input",
        this.bannerList.filter((i) => this.selectedBanner.includes(i.img))
      );
      // this.$emit(
      //   "change",
      //   this.bannerList.filter((i) => this.selectedBanner.includes(i.img))
      // );
    },
    async afterReadImage(file) {
      let imgUrl = file.content;
      this.cropperImg = imgUrl;
      this.cropperShow = true;
    },
    cropperSubmit(img) {
      if (!img) return;
      this.bannerList.push({
        img,
        link: "",
      });
      this.handleSelectedImage({ img });
      this.$toast.success("上传成功");
      this.cropperShow = false;
    },
  },
  watch: {
    value(val) {
      console.log("watch");
      this.bannerList = val;
      this.selectedBanner = this.bannerList.map((item) => item.img);
      this.$emit(
        "change",
        this.bannerList.filter((i) => this.selectedBanner.includes(i.img))
      );
    },
  },
  computed: {
    getSelectBannerInfo() {
      return this.bannerList
        .filter((i) => this.selectedBanner.includes(i.img))
        .map((item, index) => {
          return {
            ...item,
            index,
          };
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.bannerUploader {
  --item-width: 580px;
  --upload-width: 580px;
  display: flex;
  column-gap: 40px;
  row-gap: 40px;
  flex-wrap: wrap;
  * {
    box-sizing: border-box;
  }
  .upload-container {
    width: var(--item-width);
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    .van-field {
      width: 100%;
      border: 1px solid #a8a8a8;
      border-radius: 15px;
      padding: 0 25px;
    }
  }
  .item-upload {
    width: var(--upload-width);
    height: 258px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: rgba(250, 250, 252, 1);
    border: 1px solid rgba(112, 112, 112, 0.24);
    border-radius: 16px;
    box-sizing: border-box;
    .icon {
      width: 56px;
      height: 56px;
      background: url(#{$PublicEnv}/activity_my/icon_upload.png);
      background-size: 100% 100%;
    }
    .upload-tips {
      margin-top: 12px;
      font-size: 28px;
      font-weight: 400;
      color: #999999;
    }
  }
  .item-cover {
    width: var(--item-width);
    padding: 10px;
    border-radius: 16px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    &.active {
      background: url(#{$PublicEnv}/activity_my/my_activity_scene_bg.png);
      background-size: 100% 100%;
      &::after {
        content: "";
        position: absolute;
        right: 20px;
        top: 25px;
        width: 61px;
        height: 50px;
        background: url(#{$PublicEnv}/activity_my/my_activity_choose.png);
        background-size: 100% 100%;
      }
    }
    img {
      width: 100%;
      border-radius: 16px;
    }
  }
}

@media screen and (max-width: 750px) {
  .bannerUploader {
    --item-width: 41.87vw !important;
    --upload-width: 41.87vw !important;
    column-gap: 3vw;
    row-gap: 3.7vw;
    flex-wrap: wrap;
    .upload-container {
      flex-grow: 1;
      max-width: 50%;
      row-gap: 50px;
      .van-field {
        font-size: 2.5vw;
        width: 99%;
      }
    }
    .van-uploader {
      height: auto;
      flex-grow: 1;
      height: 13.73vw !important;
      max-width: 40%;
      min-width: 40vw;
      border-radius: 0;
      ::v-deep div {
        width: 100%;
        height: 100%;
      }
      .item-upload {
        .icon {
          width: 5vw;
          height: 5vw;
        }
        .upload-tips {
          margin-top: 0;
          font-size: 3.2vw;
        }
      }
    }

    .item-cover {
      width: 100%;
      padding: 1vw;
      border-radius: 0;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      &.active {
        &::after {
          right: 20px;
          top: 25px;
          width: 5vw;
          height: 4vw;
        }
      }
      img {
        width: 98%;
        border-radius: 1.2vw;
      }
    }
  }
}
</style>
