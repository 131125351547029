<template>
  <div class="bg vch" v-if="show" @click="close">
    <div
      :class="`cropper-wrapper animation-big `"
      @click.stop.prevent="() => {}"
    >
      <div class="close cursor-pointer" @click.stop.prevent="close"></div>
      <span class="title">裁剪窗口</span>
      <div class="container">
        <vue-cropper
          ref="cropper"
          :img="img"
          :output-size="option.outputSize"
          :info="option.info"
          :can-scale="option.canScale"
          :auto-crop="option.autoCrop"
          :auto-crop-width="option.autoCropWidth"
          :auto-crop-height="option.autoCropHeight"
          :fixed="option.fixed"
          :fixed-number="option.fixedNumber"
          :full="option.full"
          :fixed-box="option.fixedBox"
          :can-move="option.canMove"
          :can-move-box="option.canMoveBox"
          :original="option.original"
          :center-box="option.centerBox"
          :height="option.height"
          :info-true="option.infoTrue"
          :max-img-size="option.maxImgSize"
          :enlarge="option.enlarge"
          :mode="option.mode"
          :outputType="option.outputType"
        ></vue-cropper>
      </div>
      <div class="tools">
        <div class="tool-item cursor-pointer" @click.stop.prevent="toolBig">
          <div class="icon-big"></div>
        </div>
        <div class="tool-item cursor-pointer" @click.stop.prevent="toolSmall">
          <div class="icon-small"></div>
        </div>
        <div
          class="tool-item reset cursor-pointer"
          @click.stop.prevent="toolReset"
        >
          <span>重设</span>
        </div>
      </div>

      <div class="footer">
        <van-button @click="close" class="van-button--theme">取消</van-button>
        <van-button type="primary" @click="submit" class="van-button--theme">确定</van-button>
      </div>
    </div>
  </div>
</template>
<script>
import { VueCropper } from "vue-cropper";
export default {
  components: {
    VueCropper,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    img: {
      type: String,
      default: "",
    },
    width: {
      type: Number,
      default: 9999,
    },
    height: {
      type: Number,
      default: 9999,
    },
  },
  data() {
    return {};
  },
  computed: {
    option() {
      return {
        img: "", // 裁剪图片的地址
        outputSize: 1, // 裁剪生成图片的质量(可选0.1 - 1)
        outputType: "png", // 裁剪生成图片的格式（jpeg || png || webp）
        info: false, // 图片大小信息
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: this.width, // 默认生成截图框宽度
        autoCropHeight: this.height, // 默认生成截图框高度
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [this.width, this.height], // 截图框的宽高比例
        full: false, // false按原比例裁切图片，不失真
        fixedBox: true, // 固定截图框大小，不允许改变
        canMove: true, // 上传图片是否可以移动
        canMoveBox: true, // 截图框能否拖动
        original: true, // 上传图片按照原始比例渲染
        centerBox: false, // 截图框是否被限制在图片里面
        height: true, // 是否按照设备的dpr 输出等比例图片
        infoTrue: false, // true为展示真实输出图片宽高，false展示看到的截图框宽高
        maxImgSize: 3000, // 限制图片最大宽度和高度
        enlarge: 1, // 图片根据截图框输出比例倍数
        mode: "cover", // 图片默认渲染方式
        limitMinSize: [108, 108], // 裁剪框限制最小区域
        minCropBoxWidth: 108, // 设置最小裁切框宽度
        minCropBoxHeight: 108, // 设置最小裁切框高度
      };
    },
  },
  watch: {
    show: {
      handler: function (v) {
        if (v) {
          //
        } else {
          //
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    toolBig() {
      this.$refs.cropper.changeScale(1);
    },
    toolSmall() {
      this.$refs.cropper.changeScale(-1);
    },
    toolReset() {
      this.$refs.cropper.refresh();
    },
    close() {
      this.$emit("close");
    },
    async submit() {
      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "正在裁剪中...",
      });
      this.$refs.cropper.startCrop();
      this.$refs.cropper.getCropData(async (data) => {
        const form = new FormData();
        form.append(
          "file_name",
          this.$utils.base64ToFile(data, `cover_${Date.now()}`)
        );
        const result = await this.$http.uploads(
          `/common/ali-cloud/upload`,
          form
        );
        let imgUrl = "";
        if (result.data.code === 200 && result.data.data) {
          this.$toast.clear();
          imgUrl = result.data.data.img_url;
        } else {
          this.$toast.fail("上传失败：" + result.data?.msg);
        }
        this.$emit("submit", imgUrl);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.bg {
  position: fixed;
  z-index: 10;
  inset: 0 0 0 0;
  background: rgba(46, 46, 46, 0.4);
  * {
    box-sizing: border-box;
  }
  .cropper-wrapper {
    width: 2742px;
    height: 1448px;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #ffffff;
    position: relative;
    overflow: hidden;
    border-radius: 24px;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 26px;
      background: linear-gradient(92deg, #9865ff 0%, #3b70c9 56%, #1fe9ff 99%);
    }
    .close {
      width: 60px;
      height: 60px;
      background: url(#{$PublicEnv}/index/close.png);
      background-size: 100% 100%;
      position: absolute;
      right: 50px;
      top: 66px;
    }
    .title {
      font-size: 56px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #252727;
    }
    .container {
      margin: 0 auto;
      margin-top: 90px;
      width: 100%;
      height: 100%;
      background: #c7c7c7;
    }
    .tools {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
      .tool-item {
        border-radius: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 80px;
        background: rgba(216, 216, 216, 0.2);
        margin-right: 38px;
        &:last-child {
          margin-right: 0;
        }
        .icon-big {
          width: 48px;
          height: 48px;
          background: url(#{$PublicEnv}/cropper/icon_big.png);
          background-size: 100% 100%;
        }
        .icon-small {
          width: 48px;
          height: 48px;
          background: url(#{$PublicEnv}/cropper/icon_small.png);
          background-size: 100% 100%;
        }
      }
      .reset {
        width: 140px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 36px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #333333;
        }
      }
    }
    .footer {
      margin-top: 158px;
      margin-bottom: 100px;
      width: 100%;
      display: flex;
      justify-content: center;
      column-gap: 100px;
    }
  }
}
@media (orientation: portrait) {
  .bg {
    .cropper-wrapper {
      width: 3569px;
      height: 4905px;
      box-sizing: border-box;
      padding: 650px 143px 333px 143px;
      align-items: center;
      .close {
        width: 154px;
        height: 154px;
        right: 138px;
        top: 205px;
      }
      .title {
        font-size: 195px;
      }
      .container {
        margin-top: 307px;
        width: 3223px;
        height: 1850px;
      }
      .tools {
        margin-top: 205px;
        .tool-item {
          width: 343px;
          height: 343px;
          margin-right: 179px;
          border-radius: 36px;
          .icon-big {
            width: 246px;
            height: 246px;
          }
          .icon-small {
            width: 246px;
            height: 246px;
          }
        }
        .reset {
          width: 655px;
          span {
            font-size: 164px;
          }
        }
      }
      .footer {
        margin-top: 430px;
        .action-item {
          &.cancel {
            width: 1229px;
            height: 358px;
            span {
              font-size: 195px;
            }
          }
          &.confirm {
            width: 1229px;
            height: 471.78px;
            margin-left: 154px;
            span {
              font-size: 195px;
            }
          }
        }
      }
    }
  }
}
</style>
