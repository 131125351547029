<template>
  <div :class="`index ${$utils.isMobile().mobile ? 'mobile' : 'pc'}`">
    <cropper
      :width="300"
      :height="300"
      :show="cropperShow"
      :img="cropperImg"
      @close="cropperShow = false"
      @submit="cropperSubmit"
    ></cropper>

    <pop-top :black="true"></pop-top>
    <div class="title vc">
      <span>{{ editId ? "编辑频道" : "创建频道" }}</span>
      <span class="color-text-theme"
        >/ {{ editId ? "Edit" : "Create" }} Channel</span
      >
    </div>
    <div class="info">
      <div class="info-item">
        <div class="left">
          <span>频道名称</span>
        </div>
        <div class="right">
          <input
            class="item-input"
            v-model="name"
            placeholder="请输入频道名称"
          />
        </div>
      </div>
      <div class="info-item">
        <div class="left">
          <span>频道Logo</span>
        </div>
        <div class="right">
          <div
            :class="`item-cover cursor-pointer ${
              selectedCover == item ? 'active' : ''
            }`"
            v-for="(item, k) in cover"
            :key="k"
            @click="selectedCover = item"
          >
            <img :src="item" style="object-fit: contain" />
            <div
              class="icon-upload-wrapper cursor-pointer"
              v-if="isUploaded && k == cover.length - 1"
            >
              <van-uploader :after-read="afterRead">
                <div class="icon-upload"></div>
              </van-uploader>
            </div>
          </div>
          <van-uploader
            :after-read="afterRead"
            v-if="!isUploaded"
            class="uploader"
          >
            <div class="item-upload vch flex-column">
              <div class="icon-upload"></div>
              <span>点击上传</span>
            </div>
          </van-uploader>
        </div>
      </div>

      <div class="info-item">
        <div class="left">
          <span>频道分类</span>
        </div>
        <Select v-model="category_id" placeholder="请选择">
          <Option
            v-for="item in channelTypeList"
            :key="item.id"
            :label="item.category_name"
            :value="item.id"
          >
          </Option>
        </Select>
      </div>

      <div class="info-item">
        <div class="left">
          <span>关联活动</span>
        </div>
        <Select v-model="selectedActive" multiple placeholder="请选择">
          <Option
            v-for="item in activeList"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          >
          </Option>
        </Select>
      </div>

      <div class="info-item">
        <div class="left">
          <span>频道图片</span>
        </div>
        <div class="right">
          <uploader
            v-model="channelImageList"
            :size="{ width: 1485, height: 600 }"
            class="channelImageUploader"
          ></uploader>
        </div>
      </div>

      <div class="info-item">
        <div class="left">
          <span>频道描述</span>
        </div>
        <div class="right">
          <div id="editor">
            <!-- <Toolbar
              style="border-bottom: 1px solid #ccc"
              :editor="editor"
              :defaultConfig="toolbarConfig"
              :mode="mode"
            /> -->
            <Editor
              class="editor"
              v-model="describe"
              :defaultConfig="editorConfig"
              mode="simple"
              @onCreated="onCreated"
            />
          </div>
        </div>
      </div>

      <div class="info-item">
        <div class="left">
          <span>关联单位</span>
        </div>
        <div class="right">
          <uploader
            v-model="companyImageList"
            :size="{ width: 322, height: 109 }"
            class="companyImageUploader"
          ></uploader>
        </div>
      </div>

      <div class="info-item">
        <div class="left">
          <span>广告位</span>
        </div>
        <div class="right">
          <bannerUpload
            ref="bannerUploadRef"
            v-model="advertisement_position"
          ></bannerUpload>
        </div>
      </div>

      <div class="info-item">
        <div class="left">
          <span>三川田技术支持</span>
        </div>
        <div class="right">
          <van-switch
            v-model="show_sct_technology"
            :active-value="1"
            :inactive-value="0"
          />
          <input
            class="item-input"
            v-model="jump_url"
            placeholder="请输入链接地址"
            :disabled="!show_sct_technology"
          />
        </div>
      </div>

      <div class="info-action">
        <van-button @click="back" class="van-button--theme">返回</van-button>
        <van-button type="primary" @click="submit" class="van-button--theme">{{
          id ? "保存" : "创建"
        }}</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { routesModuleData, routesConvert, routesData } from "@/router/index";
import { wxShare } from "@/assets/js/wechatAuth.js";
import PopTop from "@/components/PopTop.vue";
import PopCropper from "@/components/PopCropper.vue";
import cropper from "@/components/cropper.vue";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import "@wangeditor/editor/dist/css/style.css";
import { Select, Option } from "element-ui";
import bannerUpload from "./bannerUpload.vue";
import uploader from "./uploader.vue";
export default {
  components: {
    PopTop,
    PopCropper,
    Editor,
    Toolbar,
    Select,
    Option,
    cropper,
    bannerUpload,
    uploader,
  },
  data() {
    return {
      id: "",
      mode: "create",
      name: "",
      count: 5,
      cover: [
        "https://sct-ieds-test.oss-cn-shenzhen.aliyuncs.com//file/file_1699255294071.png",
      ],
      selectedCover:
        "https://sct-ieds-test.oss-cn-shenzhen.aliyuncs.com//file/file_1699255294071.png",
      isUploaded: false,
      editId: "",
      cropperShow: false,
      channelImageList: [
        {
          img: "https://sct-ieds-test.oss-cn-shenzhen.aliyuncs.com//file/file_1699331174720.png",
          link: "",
        },
      ],
      selectedChannelImage: [
        "https://sct-ieds-test.oss-cn-shenzhen.aliyuncs.com//file/file_1699331174720.png",
      ],
      currentImageUploadType: "",
      companyImageList: [],
      bannerList: [],
      editor: null,
      describe: "",
      toolbarConfig: {
        toolbarKeys: ["bold", "underline", "italic", "lineHeight"],
      },
      editorConfig: { placeholder: "请输入内容..." },
      selectedActive: null,
      activeList: [],
      cropperImg: null,
      advertisement_position: [],
      jump_url: "",
      show_sct_technology: 0,
      channelTypeList: [],
      category_id: "",
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  created() {
    this.mode = this.$route.params.type;
    if (this.mode == "edit") {
      this.id = this.$route.query.id;
      this.$http
        .get(`/channel-manage/detail?id=${this.id}`)
        .then(({ data }) => {
          this.advertisement_position = data.advertisement_position || [];
          this.companyImageList = data.relation_unit || [];
          this.channelImageList = data.image || [
            {
              img: "https://sct-ieds-test.oss-cn-shenzhen.aliyuncs.com//file/file_1699331174720.png",
              link: "",
            },
          ];
          this.category_id = data.category_id;
          this.cover = [data.logo];
          this.selectedCover = data.logo;
          this.describe = data.describe;
          this.name = data.name;
          this.selectedActive = [];
          this.jump_url = data.jump_url;
          this.show_sct_technology = data.show_sct_technology;
        });
    }
    if (!this.userInfo) {
      // 未登录
      this.$router.replace({
        name: routesData.index.name,
      });
    }

    this.$http.get("/channel-manage/select-category").then(({ data }) => {
      this.channelTypeList = data;
    });

    //初始化活动列表
    this.$http
      .get(
        `/channel-manage/select-activity${
          this.id ? "?channel_id=" + this.id : ""
        }`
      )
      .then(({ data }) => {
        this.activeList = data;
        this.selectedActive = this.activeList
          .filter((i) => i.has_select)
          .map((i) => i.id);
      });
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
    cropperSubmit(imgUrl) {
      if (!imgUrl) return;
      this[this.currentImageUploadType].push(imgUrl);
      this.$toast.success("上传成功");
      this.cropperShow = false;
    },
    async submit() {
      const obj = {
        name: this.name,
        logo: this.selectedCover,
        describe: this.describe,
        image: JSON.stringify(this.channelImageList),
        relation_unit: JSON.stringify(this.companyImageList),
        advertisement_position: JSON.stringify(this.advertisement_position),
        activity_id: this.selectedActive.join(","),
        show_sct_technology: this.show_sct_technology,
        jump_url: this.jump_url,
        category_id: this.category_id,
      };
      this.id && (obj.id = this.id);
      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "保存中...",
      });
      const url =
        this.mode == "create"
          ? "/channel-manage/create"
          : "/channel-manage/save";
      this.$http
        .post(url, obj)
        .then(() => {
          this.$toast.success("保存成功");
          setTimeout(() => {
            this.$router.push("/stage/manage?tab=channel");
          }, 1000);
        })
        .finally(() => {
          this.$toast.clear();
        });
    },
    async afterRead(file) {
      this.currentImageUploadType = "cover";
      let imgUrl = file.content;
      if (imgUrl) {
        this.cropperImg = imgUrl;
        this.cropperShow = true;
      }
    },
    async afterReadImage(file, option) {
      this.currentImageUploadType = option.type;
      let imgUrl = file.content;
      this.cropperHeight = option.size.height;
      this.cropperWidth = option.size.width;
      this.cropperImg = imgUrl;
      this.cropperShow = true;
    },
    back() {
      this.$router.back();
    },
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
};
</script>
<style lang="scss" scoped>
.index {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  -webkit-text-size-adjust: 100% !important;
  text-size-adjust: 100% !important;
  -moz-text-size-adjust: 100% !important;
  background: url(#{$PublicEnv}/activity_my/my_activity_bg_pc.png);
  background-size: 100% 100%;
  position: relative;
  * {
    box-sizing: border-box;
  }
  #editor {
    width: 1462px;
    min-height: 244px;
    max-height: 500px;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #a9a9a9;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .editor {
      flex-grow: 1;
      overflow-y: auto;
    }
    ::v-deep p {
      margin: 0 !important;
    }
  }
  .title {
    position: absolute;
    top: 246px;
    left: 280px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    span {
      &:first-child {
        font-size: 56px;
        font-weight: 500;
        color: #252727;
        margin-right: 20px;
      }
      &:nth-child(2) {
        font-size: 40px;
        font-weight: bold;
      }
    }
  }
  .info {
    position: absolute;
    top: 450px;
    left: 280px;
    right: 280px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    .info-item {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 70px;
      &:last-child {
        margin-bottom: 0;
      }
      .left {
        flex-shrink: 0;
        margin-right: 62px;
        padding-top: 12px;
        min-width: 4em;
        span {
          font-size: 40px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #333333;
        }
      }
      .right {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        overflow: hidden;
        flex-wrap: wrap;
        row-gap: 44px;
        column-gap: 44px;

        .channelImageUploader {
          --item-width: 480px;
        }
        .companyImageUploader {
          --item-width: 480px;
        }

        .item-input {
          width: 1462px;
          height: 84px;
          background: transparent;
          border: 1px solid #a8a8a8;
          border-radius: 8px 8px 8px 8px;
          padding: 0 40px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: 36px;
          font-family: DIN-Medium, DIN;
          font-weight: 500;
          color: #333333;
          &::placeholder {
            font-weight: 400;
            color: #bbbbbb;
          }
        }
        .item-cover {
          width: 480px;
          height: 280px;
          padding: 10px;
          border-radius: 16px;
          position: relative;
          &.active {
            width: 480px;
            height: 280px;
            padding: 10px;
            background: url(#{$PublicEnv}/activity_my/my_activity_scene_bg.png);
            background-size: 99% 99%;
            background-position: center center;
            background-repeat: no-repeat;
            &::after {
              content: "";
              position: absolute;
              right: 20px;
              top: 25px;
              width: 61px;
              height: 50px;
              background: url(#{$PublicEnv}/activity_my/my_activity_choose.png);
              background-size: 100% 100%;
            }
          }
          img {
            width: 100%;
            height: 100%;
            border-radius: 16px;
          }
        }
        .van-uploader {
          width: 460px;
          height: 258px;
          background: rgba(250, 250, 252, 1);
          border: 1px solid rgba(112, 112, 112, 0.24);
          border-radius: 16px;
          font-size: 28px;
          font-weight: 400;
          color: #999999;
          ::v-deep div {
            width: 100%;
            height: 100%;
          }
          .item-upload {
            row-gap: 12px;
            .icon-upload {
              width: 56px;
              height: 56px;
              background: url(#{$PublicEnv}/activity_my/icon_upload.png);
              background-size: 100% 100%;
            }
          }
        }
      }
    }
    .info-action {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      column-gap: 80px;
      margin-bottom: 100px;
      margin-top: 124px;
    }
  }
}
@media screen and (max-width: 750px) {
  .index {
    .title {
      top: 845px;
      left: 220px;
      span {
        &:first-child {
          font-size: 195px;
          font-weight: bold;
        }
        &:nth-child(2) {
          font-size: 195px;
          font-weight: bold;
        }
      }
    }
    #editor {
      width: 100%;
      min-height: 6em;
      max-height: 6em;
      border-radius: 54px;
    }
    .info {
      position: relative;
      top: unset;
      left: unset;
      right: unset;
      min-height: 6159px;
      margin: 0 102px;
      margin-top: 563px;
      width: auto;
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0)
      );
      border-radius: 61px;
      box-shadow: 0 15px 154px 0px rgba(0, 0, 0, 0.1);
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 900px 120px 100px 120px;
      .info-item {
        flex-direction: column;
        margin-bottom: 8vw;
        .left {
          margin-bottom: 154px;
          span {
            font-size: 164px;
          }
        }
        .right {
          width: 100%;
          .item-input {
            width: 90%;
            height: 307px;
            border: 3px solid #a8a8a8;
            border-radius: 20px;
            font-size: 164px;
            padding-left: 80px;
            &::placeholder {
              font-size: 164px;
            }
          }
          .item-cover {
            width: 46%;
            height: 937px;
            padding: 36px;
            border-radius: 51px;
            &.active {
              width: 46%;
              height: 937px;
              padding: 36px;
              &::after {
                width: 305px;
                height: 250px;
                right: 100px;
                top: 102px;
              }
            }
            img {
              border-radius: 51px;
            }
          }
          .van-uploader {
            width: 46%;
            height: 937px;
            border-radius: 51px;
            font-size: 164px;
            .item-upload {
              row-gap: 12px;
              .icon-upload {
                width: 164px;
                height: 164px;
              }
            }
          }
        }
      }
      .info-action {
        .van-button {
          --height: 200px;
          --width: 960px;
          font-size: 130px;
        }
      }
    }
  }
}
</style>
